<template>
  <button
    :type="isSubmit ? 'submit' : 'button'"
    :class="[
      'px-4 py-1 transition duration-200 ease-in-out ',
      borderRadiusClass,
      textSizeClass,
      marginClasses,
      widthClass,
      isOutlined? 'border-2 bg-transparent shadow-none '+' '+borderColor+' '+hoveredBackgroundColor+' '+textColor+' '+hoveredTextColor: 'border-none hover:shadow-md '+backgroundColor+' '+textColor+' '+hoveredBackgroundColor+' '+hoveredTextColor,
      isTextOnly? 'border-none bg-transparent hover:bg-transparent hover:shadow-none shadow-none '+textColor+' '+hoveredTextColor:'',
      loading || isDisabled
        ? 'cursor-not-allowed'
        : 'cursor-pointer',
      isDisabled
        ? 'text-a-neutral-light border-a-neutral-light hover:bg-a-neutral-dim hover:text-a-neutral-light hover:shadow-none'
        : '',
      thickFont?'font-semibold':'font-normal'
      ,
    ]"
    :disabled="loading || isDisabled"
  >
    <span
      class="flex flex-row w-full items-center justify-center"
      v-if="loading"
    >
      <Icon
        name="svg-spinners:3-dots-scale"
        class="text-2xl"
      />
      <span
        v-if="showLoadingText"
        :style="{ color: loadingColor }"
      >
        Loading
      </span>
    </span>
    <span class="flex flex-row items-center justify-center" v-else>
      <slot ></slot>
    </span>
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  isOutlined: {
    type: Boolean,
    required: false,
    default: false,
  },
  isTextOnly:{
    type: Boolean,
    required: false,
    default: false,
  },
  showLoadingText: {
    type: Boolean,
    required: false,
    default: false,
  },
  loadingText: {
    type: String,
    required: false,
    default: "Loading...",
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  loadingColor: {
    type: String,
    required: false,
    default: "#FFFFFF",
  },
  loadingSize: {
    type: String,
    required: false,
    default: "11px",
  },
  isSubmit: {
    type: Boolean,
    required: false,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  textSizeClass:{
    type: String,
    required: false,
    default: 'text-base'
  },
  widthClass:{
    type: String,
    required: false,
    default: 'w-auto'
  },
  primaryColorClass:{
    type: String,
    required: false,
    default: ''
  },
  activeColorClass:{
    type: String,
    required: false,
    default: ''
  },
  textColorClass:{
    type: String,
    required: false,
    default: 'a-neutral-darkest'
  },
  activeTextColorClass:{
    type: String,
    required: false,
    default: ''
  },
  hoveredBackgroundColorClass:{
    type: String,
    required: false,
    default: ''
  },
  hoveredTextColorClass:{
    type: String,
    required: false,
  },
  marginClasses:{
    type: String,
    required: false,
    default: ''
  },
  borderRadiusClass:{
    type:String,
    required: false,
    default: 'rounded'
  },
  thickFont:{
    type:Boolean,
    required: false,
    default: false
  }
});

const borderColor = computed(()=>{
  return 'border-'+props.primaryColorClass;
});

const backgroundColor = computed(()=>{
  if(props.loading){
    if(props.activeColorClass){
      return 'bg-'+props.activeColorClass;
    }
    return 'bg-'+props.primaryColorClass;  
  }if(props.isDisabled){
    return 'bg-a-neutral-dim'
  }
  return 'bg-'+props.primaryColorClass;
});

const hoveredBackgroundColor = computed(()=>{
  if(props.hoveredBackgroundColorClass && !props.loading){
    return 'hover:bg-'+props.hoveredBackgroundColorClass;
  }
  if(props.loading||props.isDisabled){
    return 'hover:bg-a-neutral-dim';
  }
  return 'hover:bg-'+props.primaryColorClass;
});

const textColor = computed(()=>{
  if(props.loading){
    if(props.activeTextColorClass){
      return 'text-'+props.activeTextColorClass;
    }
    return 'text-'+props.textColorClass;
  }
  return 'text-'+props.textColorClass;
})

const hoveredTextColor = computed(()=>{
  if(props.hoveredTextColorClass){
    return 'hover:text-'+props.hoveredTextColorClass;
  }
  if(props.isTextOnly || !props.isOutlined){
      return 'hover:text-'+props.textColorClass
  }
  return 'hover:text-white';
})


</script>

<style></style>
